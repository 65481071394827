<template>
  <div class="company-container">
    <header>
      <div class="menu-item" v-for="item in menu" :key="item.key" :class="activeKey===item.key?'isSelected':''"
           @click="activeKey=item.key">{{ item.title }}
      </div>
    </header>
    <main class="scroll">
      <transition name="el-fade-in-linear">
        <component :is="activeKey" :companyId="companyId"></component>
      </transition>
    </main>
  </div>
</template>

<script>
import Apply from "./Apply";

export default {
  name: "Archives",
  components: {
    Apply,
    Register: () => import("./Register"),
    Photo: () => import("./Photo"),
    Licence: () => import("./Licence"),
    Layout: () => import("./Layout"),
    Contract: () => import("./Contract"),
    Drill: () => import("./Drill"),
    RealName: () => import("./RealName"),
    Certificate: () => import("./Certificate"),
    KeyParts: () => import("./KeyParts"),
    Notice: () => import("./Notice"),
    Committed: () => import("./Committed"),
    System: () => import("./System"),
    Duty: () => import("./Duty")
  },
  props: ['companyId'],
  data() {
    return {
      activeKey: "Apply",
      menu: [
        {title: '消防重点单位申请表 |', key: "Apply"},
        {title: '消防重点单位备案登记表 |', key: "Register"},
        {title: '实景照片 |', key: "Photo"},
        {title: '企业证照信息 |', key: "Licence"},
        {title: '平面布局及建筑图纸 |', key: "Layout"},
        {title: '消防维护合同 |', key: "Contract"},
        {title: '灭火和应急预案演练记录列表 |', key: "Drill"},
        {title: '消防管理网络实名制人员 |', key: "RealName"},
        {title: '消防重点单位身份证明材料 |', key: "Certificate"},
        {title: '消防重点部位情况 |', key: "KeyParts"},
        {title: '消防安全重点单位告知书 |', key: "Notice"},
        {title: '消防安全责任人承诺书 |', key: "Committed"},
        {title: '消防安全管理制度 |', key: "System"},
        {title: '消防安全职责', key: "Duty"}
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.company-container {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: rgba(30, 74, 115, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  position: relative;

  header {
    flex: 1;
    background-color: rgba(7, 104, 159, 0.15);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;

    .menu-item {
      float: left;
      font-size: 13px;
      margin-right: 5px;
      vertical-align: middle;
      line-height: 20px;
      cursor: pointer;
      color: #fff;
    }

    .isSelected {
      color: #409eff;
    }

  }

  main {
    flex: 13;
  }

  .scroll {
    overflow-y: scroll;
  }

}

.company-container:after {
  position: absolute;
  top: -1px;
  left: -1px;
  content: "";
  border-right: 20px solid transparent;
  border-top: 20px solid #13355b;
}
</style>
